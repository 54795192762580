@import '../../../variables';
@import '../../../colors.scss';
@import '../../../special-characters-support.scss';

.helpcenter-chat-widget--container {
  z-index: 2000;
  margin-top: 17.5rem;
  display: flex;
  flex-direction: column;

  @media #{$mediaSM} {
    margin-top: 21.25rem;
  }
}

@media print {
  .helpcenter-chat-widget--container {
    display: none;
  }
}

.helpcenter-chat-widget--button {
  background-color: $White !important;
  padding: 0.688rem !important;
}

.popupContainerStyles {

  div {
    display: flex;
    flex-direction: column;
    margin-top: 0.25rem;
    margin-right: 1.313rem;

    html[direction="rtl"] & {
      margin-left: 1.313rem;
    }

    p {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: 1rem;
      text-transform: uppercase;
      color: $ExtraDarkGray;

      @include font-language-variation(true);
    }
  }
}

.popupCloseButton {
  padding: 5px !important;
  color: $ExtraDarkGray !important;
  fill: $ExtraDarkGray !important;
}