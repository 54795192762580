@import '~blocks-web-components/dist/bedrock/scss/variables.scss';

$bannerStripHeight: 56px;

.CSRBannerStrip {
  height: $bannerStripHeight;
  width: 100%;

  z-index: 301;
  top: 0;
  background-color: var(--cat-theme-color-button-primary-background);
  font-family: var(--cat-font-family-primary);
  font-size: var(--cat-font-size-14);
  line-height: var(--cat-line-height-20);
  font-weight: var(--cat-font-weight-semibold);
}




