@import '~blocks-web-components/dist/bedrock/scss/typography-presets.scss';
@import '~blocks-web-components/dist/bedrock/scss/typography-usage.scss';
@import '../../../../variables';

.img--border {   
  border: 1px solid #F2F2F2;
  border-radius: 8px;
}

.hr--border {
  border-color: #CCC;
}

.inline-loader-container {
  position: absolute;
  top: 17%;
  inset-inline-end: 15px;
  z-index: 2;
}

.find-equipment-guide-text {
  --size-base-unit: 0px
}

.loader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.edit-button-link--padding{
  display: inline-block;
  text-align: left;
}

#edit-equipment {
  position: relative;
  z-index: 9999;
  & span {
    color: unset;
    font-size: unset;
    font-weight: unset;
    line-height: unset;
    font-family: unset;
    margin: 0px;
  }
}