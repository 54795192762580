.pcc_salesforce_chat_widget{
    --cat-popover-body-panel-padding-vertical: 16px;
    --cat-popover-body-panel-padding-horizontal: 16px;
    .cat-c-popover__panel{
        margin-bottom: 1rem;
    }
    .pcc_salesforce_chat_widget__button{
        position: fixed;
        bottom: 24px;
        right: 16px;
        z-index: 2;
        width: 0px;
        height: 0px;
        border: none;
        background: transparent;
    }
}