@import '@app/_variables';

.modal__footer {
  background: #ffffff;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.25);
}

.modal__height {
  max-height: calc(100vh - 400px);
}

@media only #{$mediaSMMax} {
  .modal__height {
    height: auto;
  }
}
