@import '../../../variables.scss';

.carousel-changes {
  @media #{$blocks-from-desktop} {
    &::part(previous-button) {
      inset-inline-start: 100px;
    }

    &::part(next-button) {
      inset-inline-end: 100px;
    }
  }
  &::part(previous-button) {
    align-items: center;
    --cat-button-icon-only-inverted-default: none;
    --cat-button-icon-only-inverted-hover: none;
    --cat-button-icon-only-inverted-focus: none;
    --cat-button-icon-only-inverted-focus: none;
    --cat-button-icon-only-inverted-active: none;
  }

  &::part(next-button) {
    align-items: center;
    --cat-button-icon-only-inverted-default: none;
    --cat-button-icon-only-inverted-hover: none;
    --cat-button-icon-only-inverted-focus: none;
    --cat-button-icon-only-inverted-focus: none;
    --cat-button-icon-only-inverted-active: none;
  }
  &::part(autoplay-button),
  &::part(footer),
  &::part(slideCount) {
    display: none;
  }
}
