@import '@app/_variables';
@import '@app/colors';

.sidenav-expander {
  span {
    margin: 0 !important;
    padding: 13px 0 !important;
  }

  > div:first-child {
    align-items: center !important;

    svg {
      width: 32px;
      height: 32px;
      
      polygon {
        fill: $ExtraDarkGray !important;
      }

      @media #{$mediaSM} { 
        width: 24px;
        height: 24px;
      }
    }
  }  
}