@import '../../../../variables';

.success-toast-container__width {
  width: calc(var(--size-base-unit) * 61.5);
  @media only #{$mediaXS} {
    width: calc(var(--size-base-unit) * 34.5);
  }
  @media only #{$mediaUnsupported}{
    width: calc(var(--size-base-unit) * 31.5);
  }
}