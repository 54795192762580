@import '../../../../../../../variables';

.attribute-icon path {
  color: $DarkGray;
  fill: $DarkGray;
}
.attribute-text {
  word-break: normal;
  font-size: 12px !important;
  line-height: 14px !important;
}

.description-decorator-button:global(.btn.btn-ghost) {
  svg {
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
    fill: $Black00;
    g,
    polygon {
      fill: $Yellow;
    }
  }
  &:hover,
  &:focus,
  &:active {
    svg {
      height: 16px;
      width: 16px;
      min-height: 16px;
      min-width: 16px;
      fill: $Black00;
      g,
      polygon {
        fill: $Yellow;
      }
    }
  }
}

.ssro-icon {
  fill: $DarkGray;
}

@media print {
  .attribute-text {
    color: $Black;
    font-size: 16px !important;
    line-height: 10px !important;
    white-space: nowrap;
    padding: 3px 0;
  }
  .attribute-icon {
    width: 10px !important;
    height: 10px !important;
    vertical-align: top;
    color: $Black !important;
    margin-top: 0%;
    path {
      color: $Black;
      fill: $Black;
    }
  }
}
