@import '@app/_variables';

.sidenav-menu-item-wrapper > .sidenav-menu-item {
  font-size: 16px !important;
  padding: 12px 16px 12px 8px !important;

  @media #{$mediaSM} {
    font-size: 14px !important;
    padding: 8px 16px 8px 8px !important;
  }
}

.sidenav-menu-item {
  max-height: 48px !important;
}

.sidenav-menu-item > div {
  white-space: normal !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
