@import '../../../_variables.scss';

.contact-us-options {
  position: relative;
  border-top: 1px solid $LightGray;
  padding-top: 20px;
  text-align: center;

  .contact-us-options__links {
    display: flex;
    justify-content: space-evenly;
    align-content: space-between;
    width: 60%;
    margin: 15px auto 30px;
  }
}

@media #{$mediaSMMax} {
  .contact-us-options {
    h2 {
      font-size: 32px !important;
      color: $ExtraDarkGray;
    }
  }
  .contact-us-options__icon {
    width: auto !important;
  }
}
.contact-us-options__icon {
  width: 15px;
}

