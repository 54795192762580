@import '@app/variables';

.notification-item {
  position: relative;

  &-banner {
    position: relative;
    top: 60px;
  }

  &:hover {
    .notification__color-bar {
      background-color: black;
    }
  }

  &:active {
    box-shadow: 0px 0px 8px $LightBlue;
  }

  .close-btn {
    fill: $MediumBlue;
  }
  &-hide {
    pointer-events: none;
    &::after {
      content: '';
      width: 100%;
      background-color: var(--cat-theme-color-background-default);
      position: absolute;
      height: 100%;
      opacity: 0.5;
    }
  }
  &-active {
    background-color: #dff1ff;
  }
  &-padding {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
  }
}

.notification-item:focus,
.notification-item:target,
.notification-item:active,
.notification-item:hover,
.notification-item:focus-within {
  .dismiss-Notification-icon {
    display: contents;
  }
 .order-status{
    color:#000
  }
}

.notification-item .dismiss-Notification-icon {
  display: none;
}

.notification-item .order-status{
  color: #3f3f3f;
}

.notification-icon {
  height: 20px;
}

@media only screen and (max-width: 480px) {
  .notification-item {
    &-banner {
      position: relative;
      top: 100px;
    }
  }
}
