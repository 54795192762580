@import './colors.scss';
@import '~blocks-web-components/dist/bedrock/scss/variables.scss';

$box-shadow--blue: 0px 0px 9px 0px $LightBlue;
$Z-INDEX: ("HeaderStickyZ_INDEX":20,
    "ROOT_INDEX":1);

$font-family-sans-serif: Arial,
"Helvetica Neue",
Helvetica,
sans-serif;
$font-family-arial-narrow: "Arial Narrow",
Arial,
"Helvetica Neue",
Helvetica,
sans-serif;
$font-family-universe: "UniversLTW02-57Condense 723821",
"Arial Narrow",
Arial,
sans-serif;
$font-family-universe--bold: "UniversLTW02-67BoldCn",
"Arial Narrow",
Arial,
sans-serif;
$font-family-font-icon: "font-icon";
// MEDIA QUERIES
$mediaUnsupported: 'screen and (max-width: 359px)';
$mediaXS: 'screen and (max-width: 767px)'; //Phones/XS devices only
$mediaSM: 'screen and (min-width: 768px)';
$mediaSMOnly: 'screen and (min-width: 768px) and (max-width: 991px)';
$mediaMSOnly: 'screen and (min-width: 768px) and (max-width: 1199px)';
$mediaSMMax: 'screen and (max-width: 991px)'; //This should be used most often for mobile styles
$mediaMD: 'screen and (min-width: 992px)'; //Desktop only
$mediaMDOnly: 'screen and (min-width: 992px) and (max-width: 1199px)';
$mediaLG: 'screen and (min-width: 1200px)';
$mediaLGOnly: 'screen and (min-width: 1200px) and (max-width: 1439px)';
$mediaXL: 'screen and (min-width: 1440px)';

// media Queries for Blocks
$blocks-mobile : '(min-width: 320px) and (max-width: #{calc(#{$cat-bp-md} - 0.02px)})';
$blocks-tablet : '(min-width: #{$cat-bp-md}) and (max-width:#{calc(#{$cat-bp-lg} - 0.02px)})';
$blocks-mobile-and-tablet : '(min-width: 320px) and (max-width: #{calc(#{$cat-bp-lg} - 0.02px)})';
$blocks-desktop : '(min-width: #{$cat-bp-lg}) and (max-width: #{calc(#{$cat-bp-xl} - 0.02px)})';
$blocks-from-desktop : '(min-width: #{$cat-bp-lg})';
$blocks-from-tablet : '(min-width: #{$cat-bp-md})';