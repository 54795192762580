@import '~blocks-web-components/dist/bedrock/scss/variables.scss';

$bannerStripHeight: 56px;
$bannerStripSmallHeight: 48px;

.CSRBannerStrip {
  width: 100%;

  z-index: 301;
  top: 0;
  background-color: var(--cat-color-neutral-white);
  font-family: var(--cat-font-family-primary);
  font-size: var(--cat-font-size-14);
  line-height: var(--cat-line-height-20);
  font-weight: var(--cat-font-weight-semibold);

  &__tooltip {
    &::part(content) {
      word-wrap: initial;
      max-width: none;
    }
  }

  &__small_height {
    height: $bannerStripSmallHeight,
  }

    &__hidden_height {
    height: 56px;
  }

  .CSRBannerStrip__user_info{
    .CSRBannerStrip__user_info__name{
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #000;
      text-align: right;
    }
    .CSRBannerStrip__user_info__user_box{
      display: flex;
      align-items: center;
    }
    .CSRBannerStrip__user_info__user_alert{
      color: #C52320;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
    .CSRBannerStrip__user_info__devider{
      background-color: #CCCCCC;
      width: 0.5px;
      height: 16px;
      margin: 0 4px;
    }
    .CSRBannerStrip__user_info__user_type{
      color: #3F3F3F;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}
