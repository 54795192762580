@import '../../../variables.scss';
.drawer_body_class > *p {
  color: initial !important;
  margin-top: 0.25rem !important;
}
.drawer_body_class {
    margin-top: -17px;
    margin-bottom: -17px;
}
.drawer_warning_icon{
  color: $MediumOrange !important;
}
.drawer_selectStore_error {
  margin-bottom: 32px;
}