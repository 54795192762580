@use 'sass:map';
@import '@app/_variables.scss';

.header--sticky {
  z-index: map.get($Z-INDEX, 'HeaderStickyZ_INDEX');
  position: sticky !important;
  top: 0; 
}

.search-add-equipment {
  color: black;
}
.search-action-button {
  border-bottom: 1px solid #e1e1e1;
  display: block;

  > :global(cat-utility-nav-item) {
    --cat-utility-nav-item-padding-inline: 0;
  }
}

.root-index {
  position: relative;
  z-index: map.get($Z-INDEX, 'ROOT_INDEX');
}
