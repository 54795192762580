@import '../../../../../variables';

.add-equipment__links {
   margin: -10px 0 12px;

  button {
  color: $MediumBlue;
    
  &:hover {
      color: $DarkBlue;
      text-decoration: none;

      svg {
        fill: $DarkBlue;
      }
    }
  }
}
.search-form {
  input {
    html[direction="rtl"] & {
      flex: 0 1 93%;
      padding: 8px 4px 8px 0px !important;
    }
  }
}
@media only #{$mediaSMMax} {
  .add-equipment__links {
    button {
      font-size: 14px;
    }
  }
}

html[direction="rtl"] {
  .search-form button[type='submit']:global(.btn-sm) {
    height: inherit;
    top: 0;
  }
  .search-form button[type='button']:global(.btn-sm) {
    right: auto;
    left: 50px;
  }
}
