.skeleton-category-card {
  width: 194px;

  &__content-height {
    height: 150px;
  }

  &__content-height-large {
    height: 250px;
  }
  
  &__label-height {
    height: 24px;
  }
}
