@import '@app/_variables';

.table__image-size {
  word-wrap: break-word;
  max-width: 96px;
}

@media (max-width: 400px) {
  .table__image-size {
    width: 72px;
    max-width: 72px;
    height: 72px;
  }
}

// Intentionally avoiding variable to include print media
@media (min-width: 768px) and (max-width: 991px) {
  .table__image-size {
    width: 72px;
    max-width: 72px;
    height: 72px;
  }
}

@media print {
  .table__image-size {
    word-wrap: break-word;
    max-width: 96px;
    filter: grayscale(100%);
  }
}
