@import '../../../../variables';

.espot__loader__blocks {
  overflow: hidden;

  &.espot__loader__blocks-CAROUSEL {
    height: 116px;
  }

  &.espot__loader__blocks-LARGE {
    height: 200px;

    @media #{$mediaXS} {
      height: 100px;
    }
  }

  &.espot__loader__blocks-PINSTRIPE {
    height: 60px;
  }
}
