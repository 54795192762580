@import '../../../../../variables.scss';

.product-list-item {
  border-bottom: 1px solid #CCCCCC !important;
}

.product-list-item__anchor:focus {
  outline: none;
}

.product-list-item_name {
  color: $MediumBlue;
}

.product-list-item:hover .product-list-item_name,
.product-list-item__anchor:focus .product-list-item_name {
  color: $DarkBlue;
  text-decoration: underline;
}

.product-list-item:hover .product-list-item_name,
.product-list-item__anchor:focus .product-list-item_name {
  color: $DarkBlue;
  text-decoration: underline;
}