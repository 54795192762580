@import '../../../../variables';

.equipment-modal__image-box {
  font-size: 18px;
}
.equipment-modal__serial-suggestions {
  margin-top: -16px;
  background: $White;
  border: 1px solid $LightGray;
}
.equipment-modal__serial-suggestions-item {
  border: 0 !important;
  border-bottom: 1px solid $LightGray !important;
  span {
    font-size: 16px;
  }
}
.serialSuggestionData {
  max-height: 200px;
}
.equipment-modal__image {
  height: 240px;
  width: 240px;
}
.equipment-modal__attribute {
  font-size: 16px;
}

.equipment-modal__value {
  line-height: 15px;
  font-size: 16px;
  color: $MediumGray;
  span {
    display: block;
    margin: 0;
    padding: 15px 0;
    font-size: 16px;
    color: $MediumGray;
  }
  input {
    font-size: 16px;
    color: $MediumGray;
    margin: 5px 0 0px 0;
    padding: 14px;
    width: 100%;
    &:focus {
      outline-color: $DarkGray;
    }
  }
}

.equipment-modal__modelWrapper {
  p {
    padding: 0;
    color: $MediumGray;
  }
  :global(p.input__label) {
    padding: 0;
    color: $DarkGray;
  }
}

.equipment-modal__attribute--opt {
  font-size: 16px;
  color: $MediumGray;
}
.equipment-modal__serial-link {
  min-height: 24px !important;
  margin-top: -3px;
  color: $MediumBlue;
  max-width: 55%;
}

.prefix-suggestions__loader-wrapper {
  height: 66px;

  .prefix-suggestions__loader-content {
    top: unset;
    padding-top: unset;
    padding-bottom: unset;

    svg {
      width: 40px;
      height: 40px;
    }
  }
}

@media only #{$mediaSMMax} {
  .equipment-modal__info {
    display: block !important;
  }
  .equipment-modal__image-box,
  .equipment-modal__image {
    width: 79px !important;
    height: 79px !important;
  }
  .equipment-modal__modelWrapper {
    position: absolute;
    left: 85px;
    top: 0;
  }
}
html[direction='rtl'] {
  .equipment-modal__content {
    text-align: right;
  }
  .equipment-modal__serial-link {
    float: left;
  }
  .equipment-modal_left-content {
    left: 0;
    right: unset;
    padding-left: 5px;
  }
  @media #{$mediaSMMax} {
    .equipment-modal__modelWrapper {
      left: auto;
      right: 85px;
    }
  }
}
.equipment-modal_toggle_sn_error {
  color: #940000;
  font-size: 12px !important;
  line-height: 14px;
}
