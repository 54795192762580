@import '../../../../variables';

.drawer-index {
  z-index: 9999 !important;
}

.modal-index {
  z-index: 10000 !important;
  position: absolute;
}

.equipment-actions {
  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    background: var(--cat-theme-color-background-default);
    border: 2px solid var(--cat-theme-color-background-default);
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 8px 12px 0px;
    width: 100px;

    &:focus {
      border: none;
    }

    &--label {
      cursor: pointer;
    }
  }
}
.equipment-actions__button-icon{
  width: 48px !important;
  height: 48px !important;
}
