@import '../../../variables';

.warning-button-icon g {
  fill: yellow;
}
.popup-text {
  font-size: 12px;
  color: $DarkGray;
  max-width: 255px;
}
