$CatYellow100: #ffcd11;
$CatYellow085: #ffd326;
$CatYellow065: #ffde59;
$CatYellow040: #ffeba0;
$CatYellow020: #fcffcf;
$Yellow: #ffff00;

$Black: #000000;
$Black00: $Black;
$ExtraDarkGray: #3f3f3f;
$DarkGray: #565656;
$MediumGray: #777777;
$LightGray: #cccccc;
$ExtraLightGray: #f2f2f2;
$White: #ffffff;

$DarkBlue: #015394;
$MediumBlue: #0078d6;
$LightBlue: #90caf9;
$ExtraLightBlue: #c2ddf1;
$LightestBlue: #e6f1fa;

$DarkGreen: #006618;
$MediumGreen: #0f992f;
$LightGreen: #96d7a5;
$ExtraLightGreen: #c9eed2;
$LightestGreen: #e6faeb;

$DarkPurple: #5b1785;
$MediumPurple: #9826de;
$LightPurple: #cd9ce6;
$ExtraLightPurple: #e4c9f3;
$LightestPurple: #f3e6fa;

$DarkRed: #940000;
$MediumRed: #de2222;
$LightRed: #f29c9c;
$ExtraLightRed: #ffd0d1;
$LightestRed: #ffe8e9;

$DarkOrange: #9c3900;
$MediumOrange: #e56c00;
$LightOrange: #ffbf7f;
$ExtraLightOrange: #ffe4c2;
$LightestOrange: #fcf2e6;
