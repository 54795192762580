@import '../../../../variables';
.login-registration-modal__footer {
  width: 100%;
  border-top: 1px solid $LightGray;
}
.login-registration-modal__text {
  font-size: 16px;
  letter-spacing: 0;
  font-style: normal;
  line-height: 20px;
  text-align: left;
}
