@import '../../../../../variables';

.serial-mismatch__warning {
  margin: 1em auto;
}
.serial-mismatch__new-match {
  
  span {
    &:first-child {
      width: 60px;
    }
  }
}
.serial-mismatch__error-message {
  font-size: 12px;
  line-height: 20px;
  padding-top: 8px;
}
.serial-mismatch__cancel-link {
  bottom: 6px;
}
@media only #{$mediaXS} {
  .serial-mismatch-btn__actions {
    padding: 1em 0;
  }
  .serial-mismatch__save-link {
    padding: 1rem !important;
    font-size: 16px !important;
  }
  .serial-mismatch__cancel-link {
    font-size: 16px !important;
    bottom: 0;
    .serial-mismatch__cancel-icon {
      width: 22px;
    }
  }
}