@import '../../../variables';
.availability-contact-dealer__popup {
  top: 5px !important;
}
.availability-text {
  text-align: end;
  vertical-align: top;
  color: $ExtraDarkGray !important;
  font-weight: bold;
}
.hose-availability-text {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  color: $DarkGray;
  text-align: start;
  white-space: normal !important;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin: 0%;
  flex-direction: column !important;
}
@media print {
  .print__availability-text {
    font-size: 8px !important;
    line-height: 10px !important;
    color: $Black !important;
  }

  .print-black-text {
    color: $Black !important;
  }
}
