@import '../../../../../variables';
.store-location__radio label {
  width: 100%;
}

.dealer-location__details-indent {
  padding-left: 28px;

  html[direction='rtl'] & {
    padding-right: 28px;
  }
  @media only #{$mediaSMMax} {
    padding-left: 40px;

    html[direction='rtl'] & {
      padding-right: 40px;
    }
  }
}
