@import '../../../../variables';

/* This CSS is used for quickorder table alignment */
.sourcing-details-content-table {
  table-layout: auto !important;
}
.sourcing_popup {
  width: 252px;
}
.availability-by-source-text {
  color: $DarkGray;
  white-space: normal;
}
.quantity-available-text {
  text-align: end;
  color: $ExtraDarkGray;
  font-weight: bold;
  vertical-align: top !important;
  padding-inline-start: 1rem !important;
  display: inline-block !important;
}
.contact-button {
  text-align: start;
  padding-inline-start: 0.25rem !important;
  color: $DarkGray;
  white-space: normal !important;
  display: inline-block !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}
.availability-by-source-text,
.quantity-available-text,
.contact-button {
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
}

@media print {
  .print__availability-text {
    font-size: 8px !important;
    line-height: 10px !important;
    color: $Black !important;
  }
}
