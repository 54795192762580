@mixin font-language-variation($bold) {
  &:lang(vi),
  &:lang(el),
  &:lang(hi),
  &:lang(ta) {
    font-family: 'Noto Sans', sans-serif !important;
    @if($bold== true){
      font-weight: bold !important;
    }
  }
}
