@import '@app/variables';

.notification-header {
  display: flex;
  justify-content: space-between;
  gap: 7px;
}

.card-approve-btn {
  background-color: #0067b8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 16px;
  border-color: #fff;
  border-radius: 30px;
  padding: 10px;
  margin-bottom: 10px;
}

.notification_drawer {
  z-index: 10000 !important;
  .notification-menu-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    float: right;
    position: relative;
  }
  &-cx-sidebar {
    z-index: 499 !important;
  }
  &-clear-filter {
    min-width: 67px;
    margin-left: 12px;
    font-size: 12px;
    padding-top: 4px;
  }

  &-popover {
    position: absolute;
    top: 50px;
    right: 100px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 0.5rem;
    transition: border-left 300ms ease-in-out, padding-left 300ms ease-in-out;
    z-index: 1000001;

    &-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 8px;
      gap: 8px;

      .notification-menu-icon {
        &-button {
          border: none;
          background-color: transparent;
          box-shadow: none;
          cursor: pointer;
          font-family: 'Noto Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    &--disable {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  &_footer {
    &_toggle {
      display: flex;
      position: absolute;
      left: 0px;
      top: 0px;
      font-size: 14px;
      align-items: center;
      gap: 8px;
    }
  }
  .footer-button-group {
    min-height: 30px;
  }
  .dismiss-confirmation-popup {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-message {
      width: calc(100% - 190px) !important;
      min-width: 112px;
    }

    &-btn {
      width: 190px;
    }
  }
}

.no-notification {
  font-weight: 600;
}

.notification_filters_drawer {
  &_toggle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &_btngroup {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    text-align: center;
  }
}

.card-approve-content {
  display: flex;
}

.notification_poNumber {
  font-size: 16px;
  color: #3f3f3f;
  font-weight: 600;
}

.fontNoto {
  font-family: var(--cat-font-family-primary);
}

.card_text {
  @extend .fontNoto;
  font-size: 14px;
  color: #3f3f3f;
  &_weight4 {
    @extend .card_text;
    font-weight: 400;
  }
  &_weight6 {
    font-size: 14px;
    font-weight: 600;
  }
  &_amount {
    @extend .fontNoto;
    color: #3f3f3f;
    font-weight: 400;
  }
  &_datetime {
    @extend .fontNoto;
    color: #777777;
    font-weight: 400;
  }
  &_header {
    @extend .fontNoto;
    color: #0078d6;
    background-color: transparent;
    font-weight: 600;
  }
  &_expired {
    @extend .fontNoto;
    font-weight: 600;
  }
}

.notification_orderCards {
  font-weight: 600 !important;
  margin-right: 5px;
}

.notification-footer {
  margin-bottom: 5px;
  .notification_container {
    display: flex;
    flex-direction: column;
    padding: 8px 0px 0px;
    gap: 8px;

    .dcn_container {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3f3f3f;
      order: 1;
    }

    .date_container {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3f3f3f;
      order: 2;
    }
  }

  .footer_order_change {
    display: flex;
    flex-direction: row;
    padding: 8px 0px 0px;
    gap: 8px;

    .dcn_container {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3f3f3f;
      order: 2;
    }

    .dcn_container::before {
      content: '•';
    }

    .date_container {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3f3f3f;
      order: 1;
    }
  }
  &--pagination::part(cat_c_pagination_dropdown) {
    display: none !important;
  }
}

.skeleton-loader {
  margin-bottom: 40px;
  padding-bottom: 120;
}

.order-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &_order-display-link {
    color: #0078d6;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }

  &_arrow-icon {
    color: rgb(0, 120, 214);
  }
}

.dealer-mail {
  font-style: normal;
  font-family: 'Noto Sans';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.left-4 {
  left: 4px;
}

.contact-dealer {
  &-location {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &-address {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &-name {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0.7rem;
  }

  &-btngroup {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    text-align: center;
  }
}

.dismiss {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  &-button {
    border: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
    font-family: inherit;
  }
  &-menu-icon {
    cursor: pointer;
    right: 0;
    top: 8px;
  }
  &-tool-tip {
    cursor: pointer !important;
    pointer-events: auto !important;
  }
}

.button:hover,
.button:focus {
  background-color: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.cat-banner {
  position: absolute;
  left: 0px;
  top: 72px;
  min-width: 90%;
  max-width: 95%;
  z-index: 1;
  &_spacing {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .cat-banner {
    max-width: 97.7%;
  }
  .dismiss-confirmation-popup {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  .contact-dealer-btngroup {
    flex-direction: column;
  }
}

.no-result-popup {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &-message {
    width: calc(100% - 190px) !important;
    min-width: 325px;
  }

  &-btn {
    width: 125px;
  }
}

@media only screen and (max-width: 480px) {
  .no-result-popup-message {
    min-width: 200px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
  }
}

.notification_filters_no_result {
  position: relative;
  top: 70px;
}

.drawer-remove-padding {
  --cat-c-drawer-body-padding: 0px;
}

.drawer-show-padding {
  --cat-c-drawer-body-padding: 16px;
}

.notification_chip_container {
  position: relative;
  margin: 1rem;

  .notification_chip_status {
    max-height: 57px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .notification_chip_expand {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .show_more_btn {
    margin-left: auto;
  }
}
html[direction='rtl'] .show_more_btn {
  margin-right: auto;
}

@media #{$blocks-mobile} {
  .notification_chip_container {
    overflow: auto;
    max-width: 100%;
    display: inline-flex;
    white-space: nowrap;

    .show_more_btn {
      display: none;
    }
    .notification_chip_status {
      overflow: auto;
      flex-wrap: inherit;
    }
    .notification_chip_expand {
      overflow: auto;
      flex-wrap: inherit;
    }
  }
}
