@import '../../../../../variables';

.grid {
  display: grid;
  grid-template-columns: auto;
  @media #{$blocks-from-tablet} {
    padding-top: 16px;
    grid-template-columns: auto minmax(320px, 1fr);
    .image {
      grid-row-start: 1;
      grid-row-end: 4;
      grid-column: 2;
      justify-self: end;
    }
  }
}
