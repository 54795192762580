.subheader {
  background-color: black;
  border-top: 1px solid var(--cat-color-neutral-70);
  position: relative;
  z-index: 4;
}

.item-border-bottom { 
  border-bottom: 1px solid var(--cat-theme-color-border-strong-hover);
}
