@import '../../../../../../_variables.scss';
.equipment-actions__container {
  display: flex;
  justify-content: start;

  @media #{$blocks-mobile} {
    display: flex;
    justify-content: flex-start;
  }
}

.equipment-actions__container-gap {
  gap: 32px;
  
  @media #{$blocks-mobile} {
    gap: initial;
  }
  @media #{$blocks-tablet} {
    gap: 10px;
  }
}

.equipment-action-card__button {
  &:first-child{
    margin-left: 0px;
    padding-left: 0px;
  }
  padding: 5px;
  background: var(--cat-theme-color-button-background);
  outline: none;
  color: var(--cat-color-neutral-100);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border: 0;

  @media #{$blocks-mobile} {
    width: 78px;
  }

  &:focus {
    outline: 2px solid var(--cat-theme-color-focus-ring);
    outline-offset: var(--cat-theme-offset-focus-ring, -2px);
    transition: all var(--cat-anim-fade-quick) var(--cat-anim-ease);
    border-radius: 0px;
    border-start-start-radius: var(
      --cat-button-border-block-start-left-radius,
      var(--cat-theme-border-radius)
    );
    border-start-end-radius: var(
      --cat-button-border-block-start-right-radius,
      var(--cat-theme-border-radius)
    );
    border-end-end-radius: var(
      --cat-button-border-block-end-right-radius,
      var(--cat-theme-border-radius)
    );
    border-end-start-radius: var(
      --cat-button-border-block-end-left-radius,
      var(--cat-theme-border-radius)
    );
  }

  &-icon {
    display: flex;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background: var(--cat-theme-color-button-background-disabled);
  }
}

.equipment-action-card__text {
  width:125px;
  @media #{$blocks-mobile} {
    max-width: 55px;
  }
}
.equipment-actions-card__wrapper {
  width: 125px;
  justify-content: center;
  @media #{$blocks-tablet} {
    width: 100px;
  }
    
}
