
@import 'node_modules/@cat-ecom/pcc-bootstrap/_variables.scss';
@import 'node_modules/@cat-ecom/pcc-bootstrap/_mixins.scss';

.dealer-customer__radio label {
  width: 100%;
}

.search-result__name {
  margin-top: -12px;
  @include media-breakpoint-up(lg) {
    margin-top: -4px;
  }
}

.search-results__details-indent {
  &:not(:lang(ar)) {
    @include media-breakpoint-up(lg) {
      padding-left: 30px;
    }
    padding-left: 40px;

  }
  &:lang(ar) {
    @include media-breakpoint-up(lg) {
      padding-right: 30px;
    }
    padding-right: 40px;
  }
}
