.footer_pcclink-list-item {
  &__icon {
    margin-right: 8px;
    float:left;
    margin-top: 2px;
  }
}

.footer__social-links__placeholder {
  width: 189.64px;
  height: 160px;
}
