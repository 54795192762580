#select-equipment {
    position:relative;
    z-index:9999;
    & span {
        color: unset;
        font-size: unset;
        font-weight: unset;
        line-height: unset;
        font-family: unset;
    }

    .loader-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}