@import '../../../../../variables.scss';
@import '../../../../../special-characters-support.scss';

.skip-checkbox {
  :global(label.custom-control-label) {
    font-family: $font-family-universe--bold;
    text-transform: uppercase;

    @include font-language-variation(true);
  }
}
