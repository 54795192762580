@import '../../../variables';
@import '../../../colors.scss';
@import '../../../special-characters-support.scss';

html[direction='rtl'] .summary-payment__order-total {
  float: left !important;
}

.summary-header__dealer-info {
  line-height: 1;
  html[direction='rtl'] & {
    float: left !important;
    text-align: left !important;
  }
}

.summary-payment__requiredLabel {
  font-family: $font-family-sans-serif;
  color: $DarkGray;
  font-size: 12px;
  font-weight: 400;

  @include font-language-variation(false);
}

.summary-payment__backLink {
  padding: 12px 32px !important;
}

.error-feedback {
  margin-bottom: 14px !important;
  padding: 0px 1rem !important;
}

.cat-card-form__container {
  .custom-control.custom-checkbox {
    margin-bottom: 9px;
  }
  .custom-control-label {
    font-size: 16px;
    font-weight: normal;
    padding-left: 10px !important;
  }
}

// This is necessary because of a bad selector in global.css
.save-credit-card-label-fix {
  label {
    font-size: 16px;
    font-weight: normal;
    color: $Black;
  }
}

.top-0-left-0 {
  top: 0;
  left: 0;
}

.z-index-0 {
  z-index: 0;
}

.uncontrolled-payment__loader-content {
  min-width: 270px !important;
}

@media screen and (max-width: 420px) {
  .uncontrolled-payment__loader-content {
    min-width: 0 !important;
  }
}

.order-by-field:disabled {
  background-color: transparent !important;
  opacity: 0.5 !important;
}

/* START: 225660: UI: PCC Modernization - Pending Quote/Summary & Payment Details - Print.CSS | PCC */
@media print {
  .row-print {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .col-print-5 {
    flex: 0 0 41.66% !important;
    max-width: 41.66% !important;
  }
  .col-print-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .col-print-7 {
    flex: 0 0 58.33% !important;
    max-width: 58.33% !important;
  }
  .mt-print-0 {
    margin-top: 0 !important;
  }
  .mb-print-0 {
    margin-bottom: 0 !important;
  }
  .mb-print-2 {
    margin-bottom: 8px !important;
  }
  .mb-print-4 {
    margin-bottom: 16px !important;
  }
  .mt-print-total-title {
    margin-top: 30px !important;
  }
  .mt-print-negative-6 {
    margin-top: -6px;
  }
  .ms-print-3 {
    margin-left: 16px !important;
  }
  .mx-print-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mx-print-3 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .px-print-order-total {
    padding: 0 15px 0 50px !important;
  }
  html[direction='rtl'] {
    .px-print-order-total {
      padding: 0 50px 0 15px !important;
    }
    .ms-print-3 {
      margin-right: 16px !important;
    }
  }
  .offset-print-5 {
    margin-left: 41.66%;
  }
  .text-print-sm {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .text-print-md {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .text-lg {
    font-size: 18px !important;
  }
  .items-in-order-table__table-print > table > tbody > tr > td {
    height: auto !important;
  }

  .items-in-order-table__table-print > table > thead > tr {
    display: flex;

    th:nth-child(1) {
      min-width: 35px;
    }

    th:nth-child(2) {
      min-width: 70px;
    }

    th:nth-child(3) {
      min-width: 115px;
    }
  }
  .items-in-order-table__table-print > table > tbody > tr {
    display: flex;

    td:nth-child(1) {
      min-width: 35px;
    }

    td:nth-child(2) {
      min-width: 70px;
    }

    td:nth-child(3) {
      min-width: 115px;
    }
  }

  //We are adding this print css with the porpuse of print all table's list items inside in the same page,
  //if we don't put this css code, list's items gonna display in a new page
  .items-in-order-table__table-print {
    table {
      break-after: auto;

      thead {
        display: table-header-group;
        break-inside: auto;

        tr {
          break-inside: auto;
          break-after: auto;

          td {
            break-inside: avoid;
            break-after: auto;
          }
        }
      }
    }
    .print-on-black {
      color: $Black;
    }

    .print-no-underline {
      text-decoration: none;
    }
  }
  .summary-order-information__ordered-by-container {
    display: contents;
  }
}
/* START: 225660: UI: PCC Modernization - Pending Quote/Summary & Payment Details - Print.CSS | PCC */

/* 368083 PCC: Customer Support - Tracking CSR actions in the UI - Inquiry Type Selector */

.dropdown {
  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  span {
    margin: 0;
  }

  .inquiryTypeText {
    color: $DarkGray;
    font-size: 1rem;
    font-weight: 700;
    margin-right: 0.5rem;
  }

  .agentUseOnlyText {
    color: $MediumGray;
    text-transform: capitalize;
  }

  .error {
    color: $DarkRed !important;
    font-size: 0.75rem;
    margin-top: -0.75rem;
    margin-bottom: 0.75rem;
  }
}

.summary-payment__pending-quote-cta__icon-rtl-rotate {
  html[direction='rtl'] & {
    transform: rotate(180deg);
  }
}
