@import '../../../_variables.scss';
@import '../../../special-characters-support.scss';

$selected-icon-color: #2677b7;

.table-hover tbody > tr:hover circle {
  fill: #cccccc;
}

.selected-equipment-row circle {
  fill: $selected-icon-color !important;
}

.my-equipment__table td {
  border-top: 1px solid $LightGray;

  div,
  p,
  span {
    font-family: $font-family-universe;
    @include font-language-variation(false);
  }
}

.my-equipment__image {
  width: 47px;
  max-width: 47px;
}

.my-equipment__image img {
  width: 37px;
}

.my-equipment__model div,
.my-equipment__model {
  -webkit-appearance: none !important;
  @media only #{$mediaSMMax} {
    display: block;
    clear: both;
  }
}

.my-equipment__assetid {
  width: 235px;
  min-width: 215px;
  max-width: 235px;
  word-wrap: break-word;
}

.icon--kabob:hover,
.icon--kabob a:hover,
.icon--kabob:hover a {
  background: $LightGray;
}
.icon--kabob a {
  text-align: center;
  width: 60px;
  background: $ExtraLightGray;
  padding: 15px 0;
}
.equipment-modal__spacer {
  width: 240px;
  flex: 1 0 240px;
}
.equipment-modal__divider {
  margin: 0 12px;
  top: 0;
  left: 0;
  right: 0;
}
.equipment-modal__remove-link {
  svg {
    margin-right: 5px;
  }
}
.myEquipment--LoadMore {
  background-color: $LightGray !important;
}
.my-equipment__icon {
  width: 30px;
  max-width: 30px;
}

.component-container {
  min-height: 100px;
}
.add-equipment p.note {
  z-index: auto;
  top: auto;
}
.add-equipment__search-suggest {
  position: absolute;
  overflow: auto;
  width: 590px;
  margin-top: -16px;
  background: $White;
  border: 1px solid $LightGray;
  box-shadow: 0px 4px 5px 0px $DarkGray;
  z-index: 1;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 300px;

    button {
      padding: 5px 7px !important;
      background: none;
      text-decoration: none;
      color: $ExtraDarkGray;

      img {
        margin-right: 7px;
        height: 32px;
        width: 48px;
      }
    }
  }
}

.add-equipment__show-results {
  line-height: 1.25;
  font-weight: 600;
}
.add-equipment__moreSuggestions {
  position: relative !important;
  top: 0;
  width: 565px;

  ul {
    border-top: 1px solid $MediumGray;
    border-bottom: 1px solid $MediumGray;
    max-height: 400px;
    overflow-y: auto;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}
.add-equipment__instructions {
  font-size: 13px;
  line-height: 1.125rem;
}
.my-equipment__box-width {
  width: 100%;
}
.add-equipment__other-suggestion-refining {
  font-family: $font-family-sans-serif;
  @include font-language-variation(false);
}

@media only #{$mediaSMMax} {
  .equipment-modal__actions {
    width: 100%;
  }
  .equipment-modal__spacer {
    flex: 0 0 0px;
  }
  .equipment-modal__action-links {
    padding: 0;
  }
  .myEquipment--LoadMore {
    width: 100% !important;
    margin-bottom: 10px;
    padding: 1rem !important;
    font-size: 16px !important;
  }
  .add-equipment {
    padding-right: 15px;

    h2 {
      font-size: 32px !important;
    }
  }
  .add-equipment__search-suggest {
    width: 100%;
  }
  .my-equipment__box-width {
    width: calc(100% - 47px);
  }
  .add-equipment__moreSuggestions {
    width: 100%;
  }
  .temp-equipment-message__container {
    width: calc(100% - 70px);
  }

  .my-equipment__table tr {
    border-top: 1px solid $LightGray;
  }

  .my-equipment__table td {
    padding: 0 5px !important;
    max-width: 210px !important;
    border: none !important;
    font-size: 12px;
  }

  .my-equipment__table td span {
    font-size: 12px;
  }

  .my-equipment__table .my-equipment__image {
    position: relative;
    padding: 10px 15px !important;
    width: 87px;
    max-width: 87px;
    word-break: break-all;
  }

  .my-equipment__table .my-equipment__image img {
    width: 57px;
    height: auto;
  }

  .my-equipment__table .my-equipment__model {
    padding-top: 10px !important;
  }
}

@media only #{$mediaXS} {
  .equipment-modal__action-save {
    width: 100% !important;
    padding: 2rem !important;
  }

  .my-equipment__assetid {
    padding-bottom: 10px !important;
    width: auto !important;
    min-width: 0;
    max-width: 100%;
  }
  .icon--kabob {
    width: 36px !important;
  }
  .icon--kabob a {
    width: 36px;
  }
  .equipment-modal__remove-link {
    margin: 0 auto;
  }
  .equipment-modal-icon__link {
    width: 22px;
  }
}

html[direction='rtl'] {
  .equipment-modal__action-links {
    text-align: right;
  }
  .add-equipment__instructions,
  p.note {
    text-align: right;
  }
  @media #{$mediaSMMax} {
    .equipment-modal__form {
      padding-right: 1rem;
    }
  }
}

.my-equipment__assets-u-margin--60px {
  margin-top: 60px;
}

.cva-list-wrapper {
  &--item {
    width: 100%;
    align-items: center;
    background: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
  }
}
