@import '../../../../variables';
.hose-availability-text {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  color: $DarkGray;
  text-align: start;
  white-space: normal !important;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin: 0%;
  flex-direction: column !important;
}
.quantity-available-text {
  text-align: end;
  color: $ExtraDarkGray;
  font-weight: bold;
  vertical-align: top !important;
  padding-inline-start: 1rem !important;
  display: inline-block !important;
}
.contact-button {
  text-align: start;
  padding-inline-start: 0.25rem !important;
  color: $DarkGray;
  white-space: normal !important;
  display: inline-block !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}
.quantity-available-text,
.contact-button {
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
}

@media print {
  .print__availability-text {
    font-size: 18px !important;
    line-height: 10px !important;
    color: $Black !important;
    cursor: text;
  }
}
/* This CSS is used for quickorder table alignment */
.modern-availability-table {
  table-layout: auto !important;
}
