@import '~blocks-web-components/dist/bedrock/scss/typography-presets.scss';
@import '~blocks-web-components/dist/bedrock/scss/typography-usage.scss';
@import '~blocks-web-components/dist/bedrock/scss/variables.scss';
@import '../../../../variables';

.background-color {
  background-color: var(--cat-theme-color-background-default-hover);
}

.loader-container {
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loader-indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.inline-loader-container {
  right: 5px;
  top: 7px;
  position: absolute;
  z-index: 2;
}

.find-equipment-guide-text {
  --size-base-unit: 0px;
}

.warning-message-box {
  color: #777;
  margin-top: size(5);
  padding-top: size(5);
  align-items: center;
}

.equipment-header-text {
  width: 300px;
}

.equipment-header-tooltip {
  vertical-align: middle;
}

#add-equipment {
  position: relative;
  z-index: 9999;
  & span {
    color: unset;
    font-size: unset;
    font-weight: unset;
    line-height: unset;
    font-family: unset;
    margin: 0px;
  }
}

.content-area {
  min-height: calc(100vh - 232px);
}

.serial-multi-result {
  position: relative;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 8px;
  box-shadow: rgba(119, 119, 119, 0.2) 0px 15px 40px 0px;
  top: 0px;
  max-height: 160px;
  overflow: auto;
}

.button-link--padding {
  display: inline-block;
  text-align: left;
}
