.skeleton-height-500px {
  height: 500px;
}

.skeleton-max-width-300px {
  max-width: 300px;
}

.skeleton-max-width-400px {
  max-width: 400px;
}
