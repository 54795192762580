
@import '~blocks-web-components/dist/bedrock/scss/typography-presets.scss';
@import '~blocks-web-components/dist/bedrock/scss/typography-usage.scss';
@import '../../../../../variables';

#delete-equipment {
  & span {
    color: unset;
    font-size: unset;
    font-weight: unset;
    line-height: unset;
    font-family: unset;
  }
}