@import '../../../../variables';

.toggle_msg {
  max-width: 239px;
  white-space: normal;
  color: $DarkGray !important;
}

.grey-icon {
  fill: $DarkGray;
}
