@import '../../../_variables';

.app-banner {
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
    
    z-index: 1;
    padding-right: 8px;

    &--hidden {
      max-height: 0px;
      padding: 0 !important;
      overflow: hidden;
    }

    &--regular {
      background-color: $White;
      padding: 10px 0;
    }

    &--new-styles {
      background-color: $Black;
    }
  }

  &__close-button {
    padding: 0 10px 0 5px !important;

    svg {
      color: $DarkGray;
      height: 27.5px;        
      width: 27.5px;  
    }
  }

  &__close-button-new-styles {
    padding: 0 10px 0 5px !important;

    svg {
      fill: $DarkGray;
      height: 10px;
      width: 15px;
    }
  }


  &__logo {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    margin-right: 10px;

    
  }

  &__logo-ios {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    margin-right: 10px;
    img {
      border-radius: 8px;
    }
  }

  &__logo-android {
    min-height: 28px;
    min-width: 28px;
    max-height: 28px;
    max-width: 28px;
    margin-right: 10px;

    img {
      border-radius: 6px;
    }
  }
}
