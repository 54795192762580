@import '~blocks-web-components/dist/bedrock/scss/variables.scss';

.popoverContainer {
    color: #000000;

    --cat-popover-body-panel-padding-vertical: 16px;
    --cat-popover-body-panel-padding-horizontal: 16px;
    
    &::part(panel-wrapper) {
        top: 18px;
    }

    &__trigger {
        cursor: pointer;
        display: flex;
    }

    &__wrapper {
        margin-top: -20px;

        &__body {

            & p,
            li {
                font-family: var(--cat-font-family-primary);
                font-size: var(--cat-font-size-14);
                line-height: var(--cat-line-height-20);
                margin: 0;
            }

            &__title {
                font-weight: var(--cat-font-weight-bold);
            }

            &__subtitle {
                font-weight: var(--cat-font-weight-regular);
            }

            &__list {
                font-weight: var(--cat-font-weight-regular);
                margin-bottom: 16px;
                padding-left: 24px;
            }
        }
    }
}