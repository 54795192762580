@import '../../../../../variables';

@media print {
  .shopping-cart-page__container {
    display: flex;
  }
  .shopping-cart-page__heading--print {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }
  .col-print-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .col-print-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .col-order-1 {
    order: 1;
  }
  .col-order-2 {
    order: 2;
  }
}
