@import '../../../../../variables';

.findmyserial__alloy--icon {
  bottom: 0.1rem !important;
}

.findmyserialitem__image {
  width: auto;
  max-width: 100%;

  &:first-of-type {
    width: 100%;
  }
}

@media #{$mediaSMMax} {
  .findmyserial__sliderdot--outer {
    &:focus {
      box-shadow: 0px 0px 8px $MediumBlue !important;
      width: 50px;
      height: 44px;
      outline: none;
    }

    background: none;
  }

  .findmyserial__sliderdot,
  .findmyserial__sliderdot--active {
    width: 20px;
    height: 20px;
    background: $LightGray;
    outline: none;
  }

  .findmyserial__sliderdot--active {
    background: $ExtraDarkGray;
  }

  .findmyserialitem__image {
    &:first-of-type {
      padding-bottom: 20px;
    }
  }
}

@media #{$mediaSM} {
  .findmyserialitem__image {
    max-width: 60%;
  }
}

@media #{$mediaMD} {
  .findmyserialitem__image {
    max-width: 48%;
  }
}

.find-my-serial__text-align-center {
  text-align: center;
}
