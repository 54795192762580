@import '@app/_variables';

.child-modal-espot__container-espot {
  max-height: calc(100vh - 280px);
}

@media #{$mediaSMMax} {
  .child-modal-espot__container-espot {
    max-height: calc(100vh - 120px);
  }
}
