.drawer,
.modal {
  display: flex;
  flex-direction: column;
  color: black;
}

.modal {
  position: fixed;
  z-index: 2200;
}

.drawer-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
}

.drawer-footer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.drawer-footer-required-text {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  color: #777;
  margin-bottom: 22px;
}

.drawer-footer-buttons {
  display: flex;
  gap: 16px;
}

.drawer-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.padding-left > div {
  padding-left: 16px;
}

.wild-card-text {
  display: flex;
  color: #3f3f3f;
  font-family: var(--cat-font-family-primary);
}

.terms-link {
  --cat-button-padding: 0px;
  --cat-font-size-14: 12px;
  --cat-font-weight-semibold: 400;
}

.cat-buttons {
  font-family: var(--cat-font-family-primary);
  font-size: var(--cat-font-size-14);
  font-weight: var(--cat-font-weight-semibold);
}
.warning-message__content {
  color: var(--cat-theme-color-content-default);
}