@use "sass:map";
@import '_variables.scss';
@import '~@cat-ecom/pcc-bootstrap/bootstrap.scss';
@import '~cat-ecommerce-alloy/dist/cat-ecommerce-alloy.css';
@import 'cmc.scss';
@import 'special-characters-support.scss';

@font-face {
  font-family: 'UniversLTW02-57Condense 723818';
  font-display: swap;
  src: url('../../../../CATSAS/fonts/6fc47523-fa84-47de-b974-533db32eacd8.eot?#iefix');
  src: url('../../../../CATSAS/fonts/6fc47523-fa84-47de-b974-533db32eacd8.eot?#iefix') format('embedded-opentype'),
    url('../../../../CATSAS/fonts/34e6ea2e-62b2-49c9-b5e6-128fddfd9f6c.woff') format('woff'),
    url('../../../../CATSAS/fonts/01a871ba-586f-4d5e-8924-d4ec3b44f723.ttf') format('truetype'),
    url('../../../../CATSAS/fonts/5ebb44ef-5450-4b20-9612-66b2a127178b.svg#5ebb44ef-5450-4b20-9612-66b2a127178b') format('svg');
}

@font-face {
  font-family: 'UniversLTW02-57Condense 723821';
  font-display: swap;
  src: url('../../../../CATSAS/fonts/aefefc4e-9cb8-4e8e-8599-dda705456734.eot?#iefix');
  src: url('../../../../CATSAS/fonts/aefefc4e-9cb8-4e8e-8599-dda705456734.eot?#iefix') format('embedded-opentype'),
    url('../../../../CATSAS/fonts/1eaf27c0-f4de-4273-9f4c-1b3236ad2f3c.woff') format('woff'),
    url('../../../../CATSAS/fonts/9b913baf-b1f0-47d2-8c76-42f59650058f.ttf') format('truetype'),
    url('../../../../CATSAS/fonts/0abcce12-2274-4ee7-b6d5-0066b5f5f670.svg#0abcce12-2274-4ee7-b6d5-0066b5f5f670') format('svg');
}

@font-face {
  font-family: 'UniversLTW02-67BoldCn';
  font-display: swap;
  src: url('../../../../CATSAS/fonts/8acd73c5-13c0-4d80-a058-56dec919199a.woff2') format('woff2'),
    url('../../../../CATSAS/fonts/b2388651-dd56-4c8e-8455-c55318f6a734.woff') format('woff'),
    url('../../../../CATSAS/fonts/346ec69d-90a7-4fef-a216-1a0dafbd3798.ttf') format('truetype'),
    url('../../../../CATSAS/fonts/827d22bc-e38b-4ccf-8a21-1f9aaa76e402.svg#827d22bc-e38b-4ccf-8a21-1f9aaa76e402') format('svg');
}

@font-face {
  font-family: 'UniversLTW02-67BoldCnOb';
  font-display: swap;
  src: url('../../../../CATSAS/fonts/6881ee98-4e9b-4409-9695-e5b0118c7c84.eot?#iefix');
  src: url('../../../../CATSAS/fonts/6881ee98-4e9b-4409-9695-e5b0118c7c84.eot?#iefix') format('embedded-opentype'),
    url('../../../../CATSAS/fonts/53f66cd7-168f-4c66-9514-9dd103c763a0.woff') format('woff'),
    url('../../../../CATSAS/fonts/774a1d99-085d-4249-8c2c-0ce23fa975a6.ttf') format('truetype'),
    url('../../../../CATSAS/fonts/056a520c-7f4a-4b3a-8465-a841cfe0b6b3.svg#056a520c-7f4a-4b3a-8465-a841cfe0b6b3') format('svg');
}

@font-face {
  font-family: $font-family-font-icon;
  font-style: normal;
  font-weight: normal;
  font-display: swap;

  src: url('../../../../CATSAS/fonts/icomoon.eot');
  src: url('../../../../CATSAS/fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('../../../../CATSAS/fonts/icomoon.ttf?') format('truetype'),
    url('../../../../CATSAS/fonts/icomoon.woff') format('woff'),
    url('../../../../CATSAS/fonts/icomoon.svg#font-icons') format('svg');
}

html[direction='rtl'] * {
  direction: rtl;
}

html[direction='rtl'] body {
  text-align: right;
}

// Used to unset poor selectors applied globally
.legacy-wrapper {

  &,
  p,
  span {
    color: $DarkGray;
  }
}

.bg-none {
  background: none !important;
}

.bg-black {
  background-color: $Black !important;
}

.bg-extra-light-gray {
  background-color: $ExtraLightGray;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.min-height-200px {
  min-height: 200px;
}

.height-30px {
  height: 30px;
}

.height-50px {
  height: 50px;
}

.height-60px {
  height: 60px;
}

.height-100px {
  height: 100px;
}

.height-200px {
  height: 200px;
}

.height-400px {
  height: 400px;
}

.min-width-35px {
  min-width: 35px;
}

.min-width-41px {
  min-width: 41px;
}

.min-width-42px {
  min-width: 42px;
}

.max-width-162px {
  max-width: 162px;
}

.text-xxxs {
  font-size: 8px !important;
}

.text-xs {
  font-size: 12px !important;
  line-height: 14px !important;
}

.text-sm {
  font-size: 14px !important;
  line-height: 16px !important;
}

.text-md {
  font-size: 16px !important;
  line-height: 20px !important;
}

.text-lg {
  font-size: 18px !important;
}

.text-xl {
  font-size: 20px !important;
  line-height: 24px;
}

.text-xxl {
  font-size: 32px !important;
  line-height: 36px !important;
}

.text-xxxl {
  font-size: 42px !important;
}

.line-height-14 {
  line-height: 14px !important;
}

.line-height-16 {
  line-height: 16px !important;
}

.line-height-1 {
  line-height: 1;
}

.line-height-125 {
  line-height: 1.25;
}

.line-height-167 {
  line-height: 1.67 !important;
}

.text-transform-none {
  text-transform: none;
}

.text-line-through {
  text-decoration: line-through;
}

.text-sans-serif {
  font-family: $font-family-sans-serif !important;
  @include font-language-variation(false);
}

.text-univers {
  font-family: $font-family-universe !important;
  @include font-language-variation(false);
}

.text-univers--bold {
  font-family: $font-family-universe--bold;
  @include font-language-variation(true);
}

.break-all {
  word-break: break-all;
}

.bottom-0 {
  bottom: 0;
}

.color-black {
  color: $Black !important;
}

.color-dark-gray {
  color: $DarkGray;
}

.color-extra-dark-gray {
  color: $ExtraDarkGray;
}

.btn__icon-small {
  width: 16px !important;
  height: 16px !important;
}

.border {
  border: 1px solid $Black !important;
}

.border-cat-secondary {
  border-color: $LightGray !important;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.current {
  transform: translateX(0%);
}

.previous {
  transform: translateX(-100%);
}

.next {
  transform: translateX(100%);
}

table {
  border-collapse: inherit !important;
}

.fyd__dealer-list-closest {
  font-size: 12px !important;
  font-weight: bold;
}

#fyd__btn-filter {
  line-height: 1em !important;
}

.fyd__filter-form h3 {
  font-size: 14px !important;
}

.u-Dark--Red {
  color: $DarkRed !important;
}

.u-fill-icon--MediumGray {
  fill: $MediumGray;
}

.u-fill-icon--ExtraDarkGray {
  fill: $ExtraDarkGray;
}

.u-fill-icon--DarkGray {
  fill: $DarkGray;
}

.u-fill-icon--CatYellow085 {
  fill: $CatYellow085;
}

.u-fill-icon--MediumGreen {
  fill: $MediumGreen;
}

.u-fill-icon--MediumOrange {
  fill: $MediumOrange;
}

.input__label {
  font-size: 1rem;
  text-transform: uppercase;
  font-family: $font-family-universe--bold;
  color: $DarkGray;
  line-height: 1;
  margin-bottom: 0;

  @include font-language-variation(true);
}

.form__section {
  margin-bottom: 24px;
}

.form__section-title {
  background: $ExtraLightGray;
  width: 100%;
  color: $ExtraDarkGray;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 28px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 24px;
}

.form__section-content {
  padding-left: 16px;
  padding-right: 16px;
}

@media only #{$mediaMD} {

  .w-lg-auto,
  .w-100.w-lg-auto {
    width: auto !important;
  }
}

/* RTL Language styles for My Equipment*/
html[direction='rtl'] {
  .kababmenu__list-item span {
    margin: 0 19px 0 0;
  }
}

/* START: 104358 - Arabic: Few UI issues in PDP */
.thumbnail {
  html[direction='rtl'] & {
    margin-right: 0 !important;
  }
}

/* END: 104358 - Arabic: Few UI issues in PDP */

/* START: 259223: UI: Suggestive Help My Equipment | MEQ Clean Up | PCC */
.find-my-modal-espot {
  p {
    text-align: left;
    margin-bottom: 20px;
  }
}

/* END: 259223: UI: Suggestive Help My Equipment | MEQ Clean Up | PCC */

.icon__returnable:before {
  color: $DarkRed;
  font-family: $font-family-font-icon;
  content: '\e907';
  font-size: 12px;
}

/* START: 371553: UI: Category Page eSpot Marketing Padding*/
@media screen and (max-width: 991px) {
  .espot__mobile-padding-bottom {
    padding-bottom: 32px !important;
  }

  .espot__mobile-padding-top {
    padding-top: 32px !important;
  }
}

/* END: 371553: UI: Category Page eSpot Marketing Padding */

/* START: 455270 - PCC - Select Store button for Map Component */
.search-this-area__button {
  position: absolute;
  z-index: 1;
  top: 11px;
  left: 10px;
}

/* END: 455270 - PCC - Select Store button for Map Component */

/* START: 697772 - PCC - Email Marketing Modal */
.z-index-2100 {
  z-index: 2100;
  position: relative;
}

/* END: 697772 - PCC - Email Marketing Modal */

#onetrust-banner-sdk {
  z-index: 500 !important;
}

/* Fix for tool tip and others components overlapping sticky nav*/
.header-sticky-on-scroll {
  position: relative;
  z-index: map.get($Z-INDEX, "ROOT_INDEX");
}

span.restart-global {
  font-family: unset;
  font-weight: unset;
  color: unset;
  font-size: unset;
  line-height: unset;
  margin: unset;
}
/* Fix for all banners to make sticky */
.sticky-banner-on-scroll{
  position:sticky;
  top:0;
  z-index: map.get($Z-INDEX, "HeaderStickyZ_INDEX");
  background-color: white;
}
.header-overlay-on-mobile{
  position:unset;
  z-index :0;
}


@media print {
  .container, body {
      min-width: 992px !important;
  }
}

.font-weight-normal, .font-weight-normal > * {
  font-weight: normal !important;
}

[class*="text_"], [class*="text-"] {
  @include font-language-variation(false);
}
