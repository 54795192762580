@import '../../../../variables';

/* This CSS is used for quickorder table alignment */
.sourcing-details-content-table {
  table-layout: auto !important;
}
.sourcing_popup {
  width: 252px;
}
.availability-by-source-text {
  color: $DarkGray;
  white-space: normal;
}
.quantity-available-text {
  text-align: end;
  color: $ExtraDarkGray;
  font-weight: bold;
  vertical-align: top !important;
  padding-inline-start: 1rem !important;
  display: inline-block !important;
}
.contact-button {
  text-align: start;
  color: $DarkGray;
  white-space: normal !important;
  display: inline-block !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}
.availability-by-source-text,
.quantity-available-text,
.contact-button {
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
}
.stock-quantity-green-color {
  color: var(--cat-color-brand-green-50);
}
.storeAvailablity {
  &__section {
    width: 70%;
  }
  &__text-color {
    color: var(--cat-color-neutral-90) !important;
  }
}
.availability_message {
  font-weight: 700;
}
.availability_inStock_message {
  color: #0f992f;
  font-weight: 700;
}
.availability_delivery_message {
  font-style: italic;
}
@media #{$mediaSMMax} {
  .storeAvailablity {
    &__section {
      width: 100%;
    }
  }
}
.cutoff-time-text{
  font-size: var(--cat-font-size-12);
  line-height: var(--cat-line-height-16);
  color: $DarkGray;
}