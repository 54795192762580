@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.resp-width {
  width: 100%;
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.modal {
  position: fixed;
  z-index: 2200;
}
