@import '../../../../../../../variables';
@import '../../../../../../../special-characters-support.scss';


.order__items-record-count {
  font-size: 16px;
  color: $DarkGray;
}

.product-description {
  font-family: $font-family-universe;
  font-size: 16px;
  line-height: 20px;
  display: inline;
  color: $DarkGray;
  a {
    color: $MediumBlue;
  }

  @include font-language-variation(false);
}

.quantity__container,
.quantity__container-disabled {
  .quantity__container-trash-icon {
    fill: $MediumBlue;
  }
  p {
    margin: 0;
    text-align: start;
    line-height: 14px;
  }
  .quantity__container-delete-icon {
    height: 48px;
    width: 44px;
    svg {
      transform: scale(1.2);
    }
  }
}

.order__items-package-quantity {
  color: $DarkGray;
  font-size: 14px;
  line-height: 16px;
  text-align: start;
}

:global(#box.shopping_cart_box) .align-core-deposite {
  h3 {
    color: $ExtraDarkGray !important;
  }
}
.order-items-table {
  &__notes-cell {
    p {
      line-height: 14px;
      span {
        line-height: 12px !important;
      }
    }
  }
}
// Intentionally avoiding variable to include print media
@media (min-width: 768px) {
  .quantity__container,
  .quantity__container-disabled {
    fieldset {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .quantity__container-delete-icon {
      height: 40px;
      width: 32px;
      svg {
        transform: scale(0.7);
        transform-origin: -3px;
      }
    }
  }
  .order-items-table__no-price-text {
    padding-left: 40px;
  }

  .align-core-deposite {
    html[direction='rtl'] & {
      align-items: flex-start !important;
    }
  }
}

// This breaks IE styles. This selector includes all browsers
// EXCEPT IE
@supports not (-ms-high-contrast: none) {
  @media (min-width: 768px) {
    .quantity__container-delete-icon {
      svg {
        html[direction='rtl'] & {
          transform-origin: 24px;
        }
      }
    }
  }
}

.product-weight {
  font-size: 14px !important;
  line-height: 16px !important;
}

.table__image-size {
  word-wrap: break-word;
  max-width: 96px;
}

@media (max-width: 400px) {
  .table__image-size {
    width: 72px;
    max-width: 72px;
    height: 72px;
  }
}

.order-items-table__total-price-display {
  font-size: 16px;
  color: $ExtraDarkGray;
}

.order-items-table__core-deposit-button {
  min-height: 12px !important;
  svg {
    height: 16px;
    width: 16px;
  }
}

.order-items-table__info-button-icon {
  fill: $DarkGray;
  html[direction='rtl'] & {
    margin-right: 0 !important;
    margin-left: 4px !important;
  }
}

.order-items-table__description-decorator-text {
  text-transform: none;
  color: $MediumBlue;
  font-size: 12px;
}

.order-items-table__core-deposit-amount {
  color: $DarkGray;
  font-size: 12px;
}

.order-items-table__no-price-text {
  font-size: 14px;
  line-height: 16px;
  color: $DarkGray;
}
.order-items-table__strikethrough-amount {
  color: $DarkGray;
  font-size: 12px;
  text-decoration: line-through;
}

.order-items-table__core-deposit-popup-text {
  max-width: 400px;
}
.core-deposit__popup {
  width: 300px;
}

@media (max-width: 576px) {
  .core-deposit__popup {
    left: 15px !important;
    width: 90%;
  }
}

.order-items-availability__wrapper {
  p {
    margin-top: 0 !important;
  }
  .server-error {
    color: $DarkRed !important;
    font-size: 14px;
  }
}
.order-items-notes__wrapper {
  width: 100px;
}

.order-items-notes__no-text-wrap {
  line-height: 10px;
  white-space: normal;
}

.delete-error-message {
  text-align: start;
  color: $DarkRed;
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 850px) {
  .order-items-availability__wrapper {
    width: 130px;
  }
}

@media (min-width: 851px) {
  .order-items-availability__wrapper {
    width: 200px;
    overflow: auto;
  }
}

// Intentionally avoiding variable to include print media
@media (min-width: 768px) and (max-width: 991px) {
  .table__image-size {
    width: 72px;
    max-width: 72px;
    height: 72px;
  }
}
@media print {
  .item-quantity,
  .order-items-table__total-price-display {
    font-size: 18px !important;
    line-height: 12px !important;
    color: $Black !important;
    padding-bottom: 3px;
  }
  .order-items-table__description-decorator-text,
  .order-items-table__core-deposit-amount,
  .order-items-table__strikethrough-amount {
    color: $Black;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    vertical-align: top;
    cursor: text;

    @include font-language-variation(false);
  }
  .print__product-description,
  .order__items-record-count {
    font-size: 18px !important;
    line-height: 12px !important;
    margin-bottom: 5px !important;
    color: $Black !important;
    padding-bottom: 3px;
  }
  table .print__order-price {
    padding-top: 0 !important;
    display: flex;
    flex-direction: column;
  }
  .product-weight {
    font-size: 16px !important;
    line-height: 10px !important;
    color: $Black;
  }
  table .order__items-record-count {
    margin-top: 4px !important;
  }
  .table__image-size {
    word-wrap: break-word;
    max-width: 36px;
    max-height: 36px;
    filter: grayscale(100%);
  }
  .order-items-notes__notes-label {
    color: $Black;
    font-size: 8px;
    line-height: 10px !important;
  }
}
