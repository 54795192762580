@import '../../../../variables';
@import '../../../../special-characters-support.scss';

.dropDownHeight {
  height: 89px;
}
@media #{$mediaMD} {
  .dropDownHeight {
    height: 77px;
  }
}

.alloy_dropdown__label {
  font-size: 1rem;
  font-family: 'UniversLTW02-67BoldCn', 'Arial Narrow', Arial, sans-serif;
  color: $DarkGray;
  line-height: 1.25;
  font-weight: normal;
  text-transform: uppercase;

  @include font-language-variation(true);
}

.ADP__PSW--label {
  font-family:$font-family-sans-serif ;
  font-size:16px;
  color: $DarkGray;

  @include font-language-variation(false);
}

.system-feedback--has-title .font-weight-bold{
  font-weight: 700 !important;
}