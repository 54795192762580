@import '_variables.scss';
/* CMC Specific Styles */
.CMC__Espot__container--lg {
  height: 200px;
}
.CMC__Espot__container--md {
  height: 150px;
}
.CMC__Espot__container--sm {
  height: 100px;
}
.CMC__Espot__container--xs {
  height: 60px;
}

.CMC__Espot__linear_Gradient {
  color: $White;
  background: $Black00;
  background: -moz-linear-gradient(
    left,
    #000000 0%,
    #3f3f3f 55%,
    #3f3f3f 55%,
    #737373 100%
  );
  background: -webkit-linear-gradient(
    left,
    #000000 0%,
    #3f3f3f 55%,
    #3f3f3f 55%,
    #737373 100%
  );
  background: linear-gradient(
    to right,
    #000000 0%,
    #3f3f3f 55%,
    #3f3f3f 55%,
    #737373 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#737373', GradientType=1);
}

.CMC__Espot__BR_15 {
  border-right: 15px $CatYellow100 solid;
}

@media only screen and (max-width: 767.98px) {
  .CMC__Espot__BR_15 {
    border-right: none;
  }
  .CMC__Espot__container--lg {
    height: 100px;
  }
  .CMC__Espot__container--md {
    height: 100px;
  }
}

/*UPDATES FOR APRIL 2022 RELEASE - NEW CMC REUSABLE CLASSES */
/*CMC Combined Reusable CSS Classes*/

/*For the Background image */
.CMC__Espot__BgImage-no-repeat {
  background-repeat: no-repeat !important;
}
.CMC__Espot__BgImage-position {
  background-position: 0 !important;
}
.CMC__Espot__BgImage-position--center {
  background-position: center !important;
}
.CMC__Espot__BgImage-position--bottom {
  background-position: 0 bottom !important;
}
.CMC__Espot__BgImage-attachment {
  background-attachment: scroll !important;
}
.CMC__Espot__BgImage-size {
  background-size: 100% auto !important;
}
.CMC__Espot__BgImage-size--cover {
  background-size: cover !important;
}
.CMC__Espot__BgImage-size--contain {
  background-size: contain !important;
}

/*For the Text Shadows for Hero Image Overlay text */
.CMC__Espot__TextShadow--lg {
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.CMC__Espot__TextShadow--md {
  text-shadow: 0 0 12px rgb(0, 0, 0);
}

/*These classes for Table (tr, td structure) */
.CMC__Espot__border-collapse {
  border-collapse: collapse !important;
}
.CMC__Espot__border-right--black3 {
  border-right: 3px #000 solid !important;
}
.CMC__Espot__border-bottom--black3 {
  border-bottom: 3px #000 solid !important;
}

/*=== Background Color Template ===*/
/*This class is to show the underline to CTA link.*/
.CMC__Espot__text--underline {
  text-decoration: underline !important;
}

/*This Class for the new design short bottom border for the heading*/
.CMC__Espot__border-bottom--short:after {
  content: '';
  height: 5px;
  width: 40px;
  margin-top: 8px;
  background: #ffcd11;
  display: block;
}

/*these classes to override the existing text size classes line height since text size classes have line height with "important" property.*/
.CMC__Espot__line-height--1 {
  line-height: 1 !important;
}
.CMC__Espot__line-height--125 {
  line-height: 1.25 !important;
}

/************ Background Image Template ***********/

/*-----Full Width Button at bottom of the banner for Desktop, Tablat and Mobile views*/
.CMC__Espot__Position--Absolute {
  position: absolute;
  left: 0;
  bottom: 0;
}
.CMC__Espot__width--940 {
  max-width: 940px !important;
}
.CMC__Espot__width--570 {
  max-width: 570px !important;
}

/*----- Classes for the Desktop RTL version -------------------------*/
html[direction='rtl'] .CMC__Espot__bgImage--rtl {
  transform: scaleX(-1);
}
html[direction='rtl'] .CMC__Espot__Position--Absolute {
  right: 0;
  left: inherit;
}
html[direction='rtl'] .CMC__Espot__BR_15 {
  border-left: 15px #ffcd11 solid !important;
  border-right: none !important;
}

/*================ MOBILE Specific Classes ================================*/
@media only screen and (max-width: 767.98px) {
  /*-----Full Width Button at bottom of the banner only for Mobile View */
  .CMC__Espot__Position--Absolute_Mob {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  /*----- Classes for the Mobile RTL version -------------------------*/
  html[direction='rtl'] .CMC__Espot__Position--Absolute_Mob {
    right: 0;
    left: inherit;
  }
  html[direction='rtl'] .CMC__Espot__BR_15 {
    border-left: none !important;
  }

  /*This class is only for MOBILE VIEW to convert the CTA Button (in Desktop/tablet view) into a CTA link in mobile view*/
  .CMC__Espot__Button--link-mob {
    background-color: transparent important;
    color: #fff !important;
    padding: 2px !important;
    min-height: 9px !important;
    line-height: 10px !important;
    margin-bottom: 0 !important;
    text-decoration: underline !important;
    font-weight: 400 !important;
    font-size: 13px !important;
  }
}

/*CMC LIST CLASSES */
.CMC__Espot__List-style--Disc {
  list-style: disc !important;
}
.CMC__Espot__List-style--loweralpha {
  list-style: lower-alpha;
}

/*Legal Notices */
#legal_cookie_privacy_espot {
  h1 { padding-bottom: 0 !important;
  }
}

/*---------------------- No changes in existing CSS ----------------------------------*/
/*================ Blocks Classes ================================*/
	.CMC__Espot__BgImage-position--right {
	  background-position: right top !important;
	}

	/*Text Default CTA White Button Class*/
	.CMC__Espot__button-default {
		font-family: var(--cat-font-family-primary);
		font-size: var(--cat-font-size-14);
		line-height: var(--cat-line-height-20);
		font-weight: var(--cat-font-weight-semibold);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: var(--cat-button-padding, calc(var(--size-base-unit) * 1.125) calc(var(--size-base-unit) * 2));
		margin: 0;
		background-color: var(--cat-theme-color-button-background);
		border: var(--cat-theme-border-width) solid var(--cat-theme-color-button-border);
		border-start-start-radius: var(--cat-button-border-block-start-left-radius, var(--cat-theme-border-radius));
		border-start-end-radius: var(--cat-button-border-block-start-right-radius, var(--cat-theme-border-radius));
		border-end-end-radius: var(--cat-button-border-block-end-right-radius, var(--cat-theme-border-radius));
		border-end-start-radius: var(--cat-button-border-block-end-left-radius, var(--cat-theme-border-radius));
		color: var(--cat-theme-color-button-content);
		width: var(--cat-theme-button-width, auto);
		text-decoration: none;
		cursor: pointer;
		transition: all var(--cat-anim-fade-quick) var(--cat-anim-ease);
	}

	/*CTA CTA Ghost Button Class*/
	.CMC__Espot__button-ghost {
		background-color: var(--cat-theme-color-button-link-background) !important;
		border-color: var(--cat-theme-color-button-link-border) !important;
		color: var(--cat-theme-color-button-link-content) !important;
		padding: var(--cat-button-padding, calc(var(--size-base-unit) * 1.03) calc(var(--size-base-unit) * 0));
	}

	/*Text CTA link Class*/
	.CMC__Espot__text-link {
		text-decoration: underline;
        color: var(--cat-theme-color-content-link-default);
        transition: all var(--cat-anim-fade-quick) var(--cat-anim-ease);
	}

	/*Border Classes*/
	.CMC__Espot__border-right--black3 {border-right:calc(var(--cat-border-width-sm)*3) var(--cat-theme-color-border-strong) solid;} 
	.CMC__Espot__border-bottom--black3 {border-bottom:calc(var(--cat-border-width-sm)*3) var(--cat-theme-color-border-strong) solid;}
	.CMC__Espot__border-left--yellow5 {border-bottom:calc(var(--cat-border-width-sm)*5) var(--cat-theme-color-border-brand) solid;}

	/*Background Color Classes*/
	.CMC__Espot__Bg-Color--yellow {background-color:var(--cat-theme-color-background-brand) !important}
	.CMC__Espot__Bg-Color--white {background-color:var(--cat-theme-color-background-default) !important}
	.CMC__Espot__Bg-Color--black {background-color:var(--cat-theme-color-background-xstrong) !important}

	/*Font Color Classes*/
	.CMC__Espot__Text-Color--yellow {color:var(--cat-color-brand-cat-yellow) !important}
	.CMC__Espot__Text-Color--white {color:var(--cat-color-neutral-white) !important}
	.CMC__Espot__Text-Color--black {color:var(--cat-color-neutral-black) !important}
	.CMC__Espot__Text-Color-Grey--light {color:var(--cat-theme-color-content-knockout-subtle) !important} /*#f2f2f2*/
	.CMC__Espot__Text-Color-Grey--dark {color:var(--cat-theme-color-content-default) !important} /*#3f3f3f*/
	.CMC__Espot__Text-Color-Blue--dark {color:var(--cat-theme-color-content-link-default) !important} /*#0067b8*/
	.CMC__Espot__Text-Color-Blue--light {color:var(--cat-theme-color-content-link-inverted-inverse) !important;} /*#4db1ff*/

	/*Font Family - Primary:Noto Sans, Secondary:Roboto Condensed*/
	.CMC__Espot__Font-primary--Noto {font-family:var(--cat-font-family-primary) !important;}
	.CMC__Espot__Font-secondary--Roboto {font-family:var(--cat-font-family-secondary);}
	.CMC__Espot__Font-weight--bold{font-weight:var(--cat-font-weight-bold) !important;}
	.CMC__Espot__Font-weight--regular{font-weight:var(--cat-font-weight-regular) !important;}

	/*Font-Size Classes*/
	.CMC__Espot__font--12{font-size:var(--cat-font-size-12) !important;}
	.CMC__Espot__font--14{font-size:var(--cat-font-size-14) !important;}
	.CMC__Espot__font--16{font-size:var(--cat-font-size-16) !important;}
	.CMC__Espot__font--18{font-size:var(--cat-font-size-18) !important;}
	.CMC__Espot__font--20{font-size:var(--cat-font-size-20) !important;}
	.CMC__Espot__font--22{font-size:calc(var(--cat-font-size-20)*22/20) !important}
	.CMC__Espot__font--24{font-size:var(--cat-font-size-24) !important;}
	.CMC__Espot__font--28{font-size:calc(var(--cat-font-size-14)*2) !important;}
	.CMC__Espot__font--32{font-size:var(--cat-font-size-32) !important;}
	.CMC__Espot__font--40{font-size:var(--cat-font-size-40) !important;}
	.CMC__Espot__font--42{font-size:calc(var(--cat-font-size-20)*42/20) !important;}

	/*Line-height Classes*/
	.CMC__Espot__line-height--16{line-height:var(--cat-line-height-16) !important;}
	.CMC__Espot__line-height--18{line-height:calc(var(--cat-line-height-20)*18/20) !important;}
	.CMC__Espot__line-height--20{line-height:var(--cat-line-height-20) !important;}
	.CMC__Espot__line-height--22{line-height:var(--cat-line-height-22) !important;}
	.CMC__Espot__line-height--24{line-height:var(--cat-line-height-24) !important;}
	.CMC__Espot__line-height--28{line-height:var(--cat-line-height-28) !important;}
	.CMC__Espot__line-height--32{line-height:var(--cat-line-height-32) !important;}
	.CMC__Espot__line-height--40{line-height:var(--cat-line-height-40) !important;}
	.CMC__Espot__line-height--42{line-height:calc(var(--cat-line-height-20)*42/20) !important;}

/*------------------------------*/
