.modal__sticky-container {
  height: 64px;
  box-shadow: 0px -4px 8px 0px #00000040;
}
.flex-container {
  flex: 1;
}
.scrollable-section {
  margin-right: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}
.scrollable-section-desktop {
  max-height: 345px;
}
.scrollable-section-mobile {
  height: calc(100% - 190px);
}